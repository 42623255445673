import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import HomePhoto from '../assets/MyPhoto.png'; 

// Main home section styled for horizontal alignment
const HomeSection = styled.section`
  height: calc(100vh - 80px); /* Subtract navbar height */
  display: flex;
  align-items: center;
  justify-content: space-between; /* Spread content across the section */
  padding: 0 40px;
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  /* Media query to handle small screens */
  @media (max-width: 768px) {
    flex-direction: column; /* Stack content vertically on small screens */
    justify-content: center; /* Center the content */
    padding: 20px;
  }
`;

const Intro = styled.div`
  max-width: 50%; /* Adjust to make space for the image */
  
  h1 {
    color: var(--primary-color);
    margin-bottom: 20px;
    font-size: 2rem;
  }

  h2 {
    color: var(--text-color);
    margin-bottom: 3px;
    font-size: 2.5rem;
  }

  p {
    color: var(--secondary-text-color);
    font-size: 1.1rem;
  }

  /* For smaller screens, make the text take full width */
  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center; /* Center text on small screens */
    margin-bottom: 20px;
    
    h2 {
      margin-top: 30px; /* Add margin to the top of the h2 heading on phones */
    }
  }
`;

// Styled component for the image
const ImageContainer = styled.div`
  max-width: 40%; /* Adjust the image size */
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%; /* Image will take the full width of the container */
    border-radius: 10px;
  }

  /* On small screens, make the image full width */
  @media (max-width: 768px) {
    width: 100$;
    margin-top: 20px;
  }
`;

const Home = () => {
  return (
    <HomeSection id="home">
      <Intro
        as={motion.div}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <h2>Sofya Klyuchereva</h2>
        <p>Here I document my experiences</p>
      </Intro>
      
      <ImageContainer>
        <img src={HomePhoto} alt="Sofya Klyuchereva" />
      </ImageContainer>
    </HomeSection>
  );
};

export default Home;
