import React from 'react';
import styled from 'styled-components';

const ProjectsSection = styled.section`
  position: relative;
  padding: 60px 20px;
  background-color: var(--bg-color);
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`;

const Heading = styled.h2`
  font-size: 2rem;
  color: var(--text-color);
  margin-bottom: 40px;
  text-align: center;
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  max-width: 1000px;
  margin: 0 auto;
`;

const ProjectCard = styled.div`
  background-color: var(--bg-color);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 16px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  color: var(--text-color);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }

  h3 {
    margin-bottom: 8px; 
    font-size: 1.25rem;
  }

  .project-year {
    font-size: 0.9rem;
    color: var(--secondary-text-color);
    margin-bottom: 8px;
  }

  p {
    font-size: 0.9rem;
    line-height: 1.4;
    color: var(--secondary-text-color);
    margin-bottom: 12px;
  }
`;

const ItemTag = styled.span`
  display: inline-block;
  background-color: #b867db;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  margin: 4px 4px 4px 0;
  font-size: 0.75rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #7e44b8;
  }
`;

const LinkRow = styled.div`
  margin-top: 10px;
`;

const ProjectLink = styled.a`
  text-decoration: none;
  color: var(--primary-color);
  font-weight: bold;
  margin-right: 16px;
  font-size: 0.9rem;
  transition: color 0.3s ease;

  &:hover {
    text-decoration: underline;
  }
`;

const Projects = () => {
  return (
    <ProjectsSection id="projects">
      <Heading>Projects</Heading>
      <ProjectGrid>

        <ProjectCard>
          <h3>Vilnius Analogue & Film Festival</h3>
          <div className="project-year">2023</div>
          <div>
            <ItemTag>Film</ItemTag>
            <ItemTag>Performance</ItemTag>
          </div>
          <p>
            Participated in the Vilnius Analogue & Film Festival, contributing to 
            visual storytelling through analogue photography.
          </p>
          <LinkRow>
            <ProjectLink
              href="https://mikiambrozy.com/spiraling-archive"
              target="_blank"
              rel="noopener noreferrer"
            >
              View Project
            </ProjectLink>
            <ProjectLink
              href="#gallery"
              style={{ cursor: "pointer" }}
            >
              View Gallery
            </ProjectLink>
          </LinkRow>
        </ProjectCard>

        <ProjectCard>
          <h3>Exploring Cultural Identity through Patterns of Change</h3>
          <div className="project-year">2022-2023</div>
          <div>
            <ItemTag>Film</ItemTag>
            <ItemTag>Photography</ItemTag>
            <ItemTag>Design</ItemTag>
          </div>
          <p>
            A creative portfolio exploring cultural identity through a 
            systematic lens of adaptation.
          </p>
          <LinkRow>
            <ProjectLink
              href="https://drive.google.com/file/d/1z_tjNAhDG4aGaZTzHo2FHOHsADdbq5XA/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              View PDF
            </ProjectLink>
          </LinkRow>
        </ProjectCard>

      </ProjectGrid>
    </ProjectsSection>
  );
};

export default Projects;
